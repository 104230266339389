import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

function Header () {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <nav className="flex flex-wrap justify-between p-6 mb-6 bg-gray-100 bg-opacity-20 sm:bg-opacity-0">
      <div className="flex items-center flex-shrink-0 mr-6 text-black">
        <Link to="/">
          <StaticImage src="../images/gold-logo.png" alt="Company Logo" className="mb-2 max-w-[260px] max-h-32 object-contain  w-3/4 sm:w-full" />
        </Link>
      </div>
      {/* Mobile */}
      <div className="block sm:hidden">
        <button
          onClick={() => toggleExpansion(!isExpanded)}
          className="flex items-center px-3 py-2 text-black border border-black rounded hover:text-black hover:border-black"
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      {/* Desktop */}
      <div
        className={`${
          isExpanded ? 'block' : 'hidden'
        } w-full block flex-grow sm:flex  sm:w-auto`}
      >
        <div className="text-sm sm:flex-grow sm:flex sm:justify-end">
          <Link
            to={'/'}
            href="#responsive-header"
            className="block mt-4 mr-4 text-blue-900 sm:inline-block sm:mt-0 hover:underline font-semibold md:text-lg"
          >
            Home
          </Link>
          <Link
            to={'/services/'}
            className="block mt-4 mr-4 text-blue-900 sm:inline-block sm:mt-0 hover:underline font-semibold md:text-lg"
          >
            Services
          </Link>
          <Link
            to={'/contact/'}
            className="block mt-4 mr-4 text-blue-900 sm:inline-block sm:mt-0 hover:underline font-semibold md:text-lg"
          >
            Contact us
          </Link>
        </div>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ''
}

export default Header
