import React from 'react'
import Layout from '../components/layout'
import Header from '../components/header'
import ContactForm from '../components/ContactForm'

const Contact = (props) => (
  <Layout>
    <Header siteTitle={'Norfolk Current'} />

    <section className="bg-white">
      <div className="pt-8 pb-8 lg:pt-0 lg:pb-16 px-4 mx-auto max-w-screen-lg">
        <h2 className="mb-4 text-4xl font-semibold text-center text-gray-900 sm:mb-10">Contact Us</h2>
        <p className="mb-8 lg:mb-14 font-light text-center text-gray-500 sm:text-xl">For enquiries or quotes, please fill out the contact form below and we'll get back to you promptly.</p>
        <div className="md:grid md:grid-cols-2 md:gap-10">

          <ContactForm />

          <div className="mb-20 mb:mb-0">
            <h3 className="mb-4 text-2xl font-semibold">Our Information</h3>
            <h3 className="mt-5 text-lg font-semibold leading-6 text-black">
              Phone number
            </h3>
            <p className="mt-2 text-lg text-gray-800 hover:underline">
              <a href="tel:07469 469586">07469 469586</a>
            </p>
            <h3 className="mt-5 text-lg font-semibold leading-6 text-black ">
              Email
            </h3>
            <p className="mt-2 text-lg text-gray-800 hover:underline">
              <a href="mailto:ciarant@norfolkcurrent.co.uk">ciarant@norfolkcurrent.co.uk</a>
            </p>
            <h3 className="mt-5 text-lg font-semibold leading-6 text-black">
              Address
            </h3>
            <p className="mt-2 text-lg text-gray-800">
              117 Marlborough Road <br />
              Norwich <br />
              NR3 4PL
            </p>
            <h3 className="mt-5 text-lg font-semibold leading-6 text-black">
              Hours
            </h3>
            <p className="mt-2 text-lg text-gray-800">
              Monday - Friday: 9am - 5pm
            </p>

            <p className="mt-4">Our business operates primarily in Norfolk, but we're happy to discuss opportunities elsewhere. Please contact us for more details.</p>
          </div>
        </div>
      </div>
    </section>

  </Layout>
)

export default Contact
